import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';

export enum TwtTwilightType {
  FRONT = 'front',
  BACK = 'back',
  BOTH = 'both',
}

export default createPerformableConfig(
  'twilight',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      instructions: z.string().optional(),
      type: z.nativeEnum(TwtTwilightType).default(TwtTwilightType.BOTH),
    }),
  },
  {
    name: 'Twilight Exterior Only',
    color: '#484CAD',
    short:
      'If you require just Twilight Exteriors or need your Twilight Exteriors shot separately from your daytime photos, please select this service.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail(context) {
      if (context['type'] === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/twilight/authentic/commercial.jpg';
      }

      return 'tenant/twt/twilight/authentic/1.jpg';
    },
    images: [],
  },
);
