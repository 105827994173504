import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';

export default createPerformableConfig(
  'video',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      voiceover: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Property Interior Video',
    short: 'Fully edited Property video capturing the highlights of the property both inside and outside of the home.',
    tenant: TenantId.TWIST_TOURS,
    color: '#819836',
    thumbnail(metadata: Record<string, unknown>) {
      if ('type' in metadata && metadata.type === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/video/commercial.png';
      }

      return 'tenant/twt/video/thumbnail.png';
    },
    images: [],
  },
);
